import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, FormHelperText, IconButton, Typography } from '@material-ui/core';
import TextField from '../../../../components/main/CustomFields/TextField';
import { ReactComponent as EditDarkIcon } from '../../../../assets/icons/edit_dark.svg';
import { ReactComponent as TotalFilledCheckmarkIcon } from '../../../../assets/icons/green_checkmark.svg';
import { ReactComponent as PartialFilledCheckmarkIcon } from '../../../../assets/icons/green_checkmark_2.svg';
import LimitationWidget from '../../../../components/main/LimitationWidget';

import { useStyles } from './styles';
import { getUrlAndParams } from '../../../../helpers/other';

interface Props {
  values: any;
  template: any;
  errors: any;
  templateMetadata: any;
  validationSchema: any;
  setTemplateMetadata: (data: any) => void;
  getCompletedFields: (startName: string, values: any) => { all: any, required: any };
}

const Header: React.FC<Props> = ({
  values,
  template,
  errors,
  templateMetadata,
  validationSchema,
  setTemplateMetadata,
  getCompletedFields,
}) => {
  const [showNameAndDescriptionBlock, setShowNameAndDescriptionBlock] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    const { params } = getUrlAndParams();
    const target_email_id = params.get('target_email_id');

    setShowNameAndDescriptionBlock(!target_email_id);
  }, []);

  const getProgressBars = () => {
    let result = {};

    template.sections.forEach(item => {
      const { required } = getCompletedFields(item.id, values);

      result = {
        ...result,
        ...required.fields
      }
    });

    return result;
  }

  const handleTemplateMetadata = (field, value, active = true) => {
    setTemplateMetadata({
      ...templateMetadata,
      [field]: {
        ...templateMetadata[field],
        value,
        active
      }
    })
  }

  const getTemplateDefaultName = () => {
    return template.category === 'MARKETING'
      ? 'Marketing template'
      : template.category === 'SALES'
        ? 'Sales template'
        : 'Product template'
  }

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerBreadCrumbs}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography style={{ fontSize: '20px', fontWeight: '600', lineHeight: '30px' }}>
              {getTemplateDefaultName()}
            </Typography>
            <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
              /
            </Typography>
            <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
              {template?.name}
            </Typography>
          </Box>
          <LimitationWidget
            type='TACTICAL_OUTREACH'
          />
        </Box>
        {!!Object.values(getProgressBars()).length && (
          <Box className={classes.headerProgressBar}>
            <Box className={classes.headerProgressBarLeftBox}>
              {Object.values(getProgressBars()).every(item => item)
                ? <TotalFilledCheckmarkIcon />
                : Object.values(getProgressBars()).some(item => item)
                  ? <PartialFilledCheckmarkIcon />
                  : null
              }

              <Box style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: '600', lineHeight: '14px', color: '#475569' }}>
                  {Object.values(getProgressBars()).every(item => item)
                    ? 'Great email'
                    : Object.values(getProgressBars()).some(item => item)
                      ? 'Good email'
                      : 'Weak email'
                  }
                </Typography>
                <Typography style={{ fontSize: '10px', fontWeight: '500', lineHeight: '12px', color: '#475569' }}>
                  {Object.values(getProgressBars()).every(item => item)
                    ? 'Your effort worth great result!'
                    : Object.values(getProgressBars()).some(item => item)
                      ? 'Fill in additional options to make email stronger'
                      : 'Fill in all obligatory options to proceed'
                  }
                </Typography>
              </Box>
            </Box>
            <Box className={classes.headerProgressBarRightBox}>
              {Object.entries(getProgressBars()).map(([key, value], index) => (
                <Box
                  key={index}
                  style={{
                    width: '100%',
                    minWidth: '40px',
                    maxWidth: '100%',
                    height: '4px',
                    border: `1px solid ${value ? '#059669' : '#475569'}`,
                    background: value ? '#059669' : 'transparent',
                    borderRadius: '8px'
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {showNameAndDescriptionBlock && (
        <Box className={classes.templateNameAndDescription}>
          {templateMetadata.name.active
            ? <Box className={classes.templateName}>
                <TextField
                  value={templateMetadata.name.value}
                  placeholder={'Name your campaign'}
                  autoFocus
                  error={templateMetadata.name.value.trim().length > 50 ? 'Max 50 characters' : ''}
                  onChange={(event) => handleTemplateMetadata('name', event.target.value)}
                  onBlur={() => handleTemplateMetadata('name', templateMetadata.name.value.trim().length ? templateMetadata.name.value.trim() : `${getTemplateDefaultName()} ${moment().format('DD-MMM-YYYY HH:mm')}`, false)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();

                      handleTemplateMetadata('name', templateMetadata.name.value.trim().length ? templateMetadata.name.value.trim() : `${getTemplateDefaultName()} ${moment().format('DD-MMM-YYYY HH:mm')}`, false)
                    }
                  }}
                />
              </Box>
            : <Box className={classes.templateName}>
                <Typography
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '38px', fontWeight: '600', lineHeight: '46px', color: '#000000' }}
                  onClick={() => handleTemplateMetadata('name', templateMetadata.name.value)}
                >
                  {templateMetadata.name.value}
                  <IconButton>
                    <EditDarkIcon />
                  </IconButton>
                </Typography>
                {templateMetadata.name.value.trim().length > 50 && (
                  <FormHelperText error>
                    Max 50 characters
                  </FormHelperText>
                )}
              </Box>
          }
          {templateMetadata.description.active
            ? <Box className={classes.templateDescription}>
                <TextField
                  value={templateMetadata.description.value}
                  placeholder={'Describe your campaign'}
                  autoFocus
                  error={templateMetadata.description.value.trim().length > 300 ? 'Max 300 characters' : ''}
                  onChange={(event) => handleTemplateMetadata('description', event.target.value)}
                  onBlur={() => handleTemplateMetadata('description', templateMetadata.description.value.trim(), false)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();

                      handleTemplateMetadata('description', templateMetadata.description.value.trim(), false)
                    }
                  }}
                />
              </Box>
            : <Box className={classes.templateDescription}>
                <Typography
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '18px', fontWeight: '500', lineHeight: '27px', color: '#475569' }}
                  onClick={() => handleTemplateMetadata('description', templateMetadata.description.value)}
                >
                  {templateMetadata.description.value || 'Edit Description'}
                  <IconButton>
                    <EditDarkIcon />
                  </IconButton>
                </Typography>
                {templateMetadata.description.value.trim().length > 300 && (
                  <FormHelperText error>
                    Max 300 characters
                  </FormHelperText>
                )}
              </Box>
          }
        </Box>
      )}
    </>
  );
};

export default Header;

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: '0px',
    // height: "100vh",
  },
  paperStyle: {
    padding: "20px",
    width: "520px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
  },
  typographyStyle1: {
    margin: "0px 0px 10px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px",
  },
  typographyStyle2: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)",
  },
  signupform: {
    maxWidth: "520px",
    width: "520px",
    height: 'fit-content',
    borderRadius: "28px",
    padding: "40px",
    background:
      "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    border: "1px solid rgba(203, 213, 225, 1)",
    boxSizing: 'border-box'
  },
  inputFeildText: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: "1rem"
  },
  googlebtn: {
    height: "45px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    boxShadow: "none",
    width: "100%",
    "&:hover": {
      background: "#FFF",
    },

  },
  googleImg: {
    color: "#000",
    marginLeft: "8px",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center"
  },
  googleTxt: {
    marginLeft: "6px",
    fontWeight: 600,

  },
  submitBtn: {
    margin: "32px 0 16px 0",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      background: "#000"
    }
  },
  termsAndCondition: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  link: {
    fontSize: '12px',
    cursor: "pointer",
    color: "#3A84C9",
    fontWeight: 400
  },
  subHeadingtypographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px",
    color: "#475569"
    // marginTop: "10px",
  },
  inputFeilds: {
    margin: "15px 0px",
  },
  tnc: {
    display: "flex",
    alignItems: "center",
    marginTop: '16px'
  },
  receiveCode: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    color: "rgba(71, 85, 105, 1)",
  },
  divider: {
    width: "100%",
    display: "flex",
    height: 21,
    justifyContent: "center",
    position: "relative",
    alignItems: "center"
  },
  dividerTextWrapper: {
    padding: "0 37.5px",
    position: "relative"
  },
  dividerText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#475569"
  },
  hr: {
    background: "#CBD5E1",
    height: "1px",
    flex: 1
  },
  errorValidate: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px",
  },
  inputFeildBox: { margin: "20px 0px" },
  inputFeildBox2: { margin: "20px 0px" },
  emailOuterBox: { marginTop: "30px" },
  validateError: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px"
  },
  input: {
    height: "42px",
    marginTop: "4px",
    borderRadius: " 8px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        height: "44px",

      },
      "&.Mui-disabled fieldset": {
        borderColor: "transparent",
        opacity: 1,
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 12px",
        lineHeight: "21px",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1
      },

      "& .MuiInputBase-input": {
        height: "44px"
      }
    }
  },
  subHeadingWrapper: {
    marginBottom: "32px",
  }
}));

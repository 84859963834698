import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  grouppedRadioButtonWrapper: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '0px',
    maxHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    overflow: 'hidden',
    gap: '0px',
    padding: '0px',
    boxSizing: 'content-box',
    [theme.breakpoints.down(1400)]: {
      maxHeight: '700px',
    },
    [theme.breakpoints.down(1310)]: {
      maxHeight: '800px',
    },
    // [theme.breakpoints.down(1286)]: {
    //   maxHeight: '800px',
    // },
    [theme.breakpoints.down(1280)]: {
      maxHeight: '600px',
    },
    // [theme.breakpoints.down(1184)]: {
    //   maxHeight: '600px',
    // },
    [theme.breakpoints.down(1086)]: {
      maxHeight: '900px',
    },
  },
  grouppedRadioButton: {
    minWidth: '48%',
    maxWidth: '48%',
    display: 'flex',
    flexDirection: 'column',
  },
  paddings: {
    padding: '8px 0px 0px 30px',
  },
  paddings2: {
    padding: '0px 0px 0px 30px',
  },
  gaps: {
    gap: '24px',
  },
  nestedSeparator: {
    width: '2px', 
    height: '75%', 
    background: '#3A84C9', 
    borderRadius: '2px',
    position: 'absolute',
    top: '25px',
    left: '17px',
  }
}));
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF'
  },
  container: {
    width: '100%',
    maxWidth: '1920px',
    height: '100vh',
    background: 'white',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    minHeight: '66px',
    padding: '12px 32px 12px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#F1F5F9',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  // content: {
  //   width: '100%',
  //   maxWidth: '1080px',
  //   minWidth: '650px',
  //   height: '80%',
  //   padding: '32px 20px 20px 20px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   rowGap: '20px',
  //   margin: '0 auto'
  // },
  // footer: {
  //   width: '100%',
  //   maxWidth: '1080px',
  //   minWidth: '650px',
  //   padding: '0px 20px 40px 20px',
  //   display: 'flex',
  //   rowGap: '20px',
  //   margin: '0 auto'
  // },
  // actions: {
  //   width: 'fit-content',
  //   display: 'flex',
  //   alignItems: 'center',
  //   padding: '8px 12px',
  //   gap: '12px',
  //   background: '#F8FAFC',
  //   borderRadius: '8px',
  // },
  nameBlock: {
    width: '30%',
    // minWidth: '20%',
    // maxWidth: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  tag: {
    width: 'fit-content',
    padding: '4px 12px',
    border: '1px solid #059669',
    borderRadius: '26px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    color: '#059669'
  },
  // editor: {
  //   position: 'relative',
  //   width: '100%',
  //   height: '90%',
  //   overflow: 'hidden',
  //   padding: '12px',
  //   border: '1px solid #94A3B8',
  //   borderRadius: '8px',
  //   '& .MuiFormControl-root': {
  //     height: '100% !important'
  //   },
  //   '& .MuiFormControlLabel-root': {
  //     height: '100% !important'
  //   },
  //   '& .MuiTextField-root': {
  //     height: '100% !important'
  //   },
  //   '& .MuiInputBase-multiline': {
  //     height: 'fit-content !important'
  //   }
  // },
  // editorWrapper: {
  //   height: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   gap: '20px',
  // },
  // editorToolbar: {
  //   display: 'none !important',
  //   // margin: '0px !important',
  //   // padding: '12px !important',
  //   // border: 'none !important',
  //   // background: '#F8FAFC !important',
  //   // borderRadius: '8px !important',
  // },
  // toolbarButton: {
  //   display: 'none !important',
  // },
  // editorTextarea: {
  //   // height: 'calc(100% - 100px) !important',
  //   height: '100%',
  //   '& .DraftEditor-root': {
  //     height: '95%',
  //     '& .DraftEditor-editorContainer': {
  //       height: '100%',
  //       '& .public-DraftStyleDefault-block': {
  //         margin: '0px !important'
  //       }
  //     }
  //   }
  // },
  // preview: {
  //   position: 'absolute',
  //   width: '100%',
  //   height: '100%',
  //   border: '1px solid #94A3B8',
  //   padding: '18.5px 14px',
  //   borderRadius: '8px',
  //   cursor: 'text',
  //   top: 0,
  //   left: 0,
  //   background: 'white',
  //   overflowX: 'hidden',
  //   overflowY: 'auto',
  //   fontSize: '14px',
  //   fontWeight: 400,
  //   lineHeight: '21px',
  //   color: '#000000',
  //   opacity: 1,
  //   zIndex: 3
  // },
  // previewHidden: {
  //   opacity: 0,
  //   zIndex: 1
  // },
  // previewControl: {
  //   width: 'fit-content',
  //   marginTop: '-10px',
  //   '& .MuiButtonBase-root': {
  //     '&:hover': {
  //       backgroundColor: '#0000000A !important',
  //     }
  //   },
  //   '& .Mui-checked': {
  //     '& .MuiSwitch-thumb': {
  //       backgroundColor: '#3a84c9 !important',
  //     }
  //   },
  //   '& .MuiSwitch-track': {
  //     backgroundColor: '#d9e7f3 !important',
  //   }
  // },
  // varticalSeparator: {
  //   width: '1px',
  //   height: '28px',
  //   background: '#CBD5E1',
  // },
}));

import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, Button, Checkbox, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import TextField from "../../../components/main/CustomFields/TextField";
import CheckboxCheckedIcon from '../../../assets/icons/checkbox_checked.svg';
import CheckboxIcon from '../../../assets/icons/checkbox.svg';
import { getProfile } from "../../../redux/services/profile";
import { clearStorages } from "../../../helpers/other";
import { confirmOtpCode, sendOtpCode, updateAccountDetails } from "../../../redux/services/authentication";
import StorageProvider from "../../../framework/main/StorageProvider";

import { useStyles } from "./styles";
import { enqueueSnackbar } from "notistack";

const AccountDetails = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    handleGetProfile();
  }, []);

  const handleGetProfile = async () => {
    setLoading(true);

    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    } else {
      await clearStorages();

      history.push('/');
    }

    setLoading(false);
  }

  const handleSaveDetails = async ({
    name,
    cName,
    password,
    phone,
    addToCommunity
  }) => {
    setLoading(true);

    const { data, error } = await updateAccountDetails({
      full_name: name,
      company_name: cName,
      password: password,
      password_confirmation: password,
      phone: phone,
      addToCommunity
    });

    if (data && !error) {
      history.push('/about-yourself');
    } else {
      enqueueSnackbar(
        Object.values(error.data.errors[0]).join() || 'Something went wrong',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.layoutContainer}>
        <Box className={classes.viewBox}>
          <Typography className={classes.createAccount}>
            Create your account
          </Typography>
          <Typography className={classes.signingAs}>
            You are signing up as {profile?.attributes?.user_email}
          </Typography>

          <Formik
            initialValues={{
              name: "",
              cName: "",
              password: "",
              phone: "",
              addToCommunity: true
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s\-']*$/, "Invalid name.")
                .max(1000, 'Max 1000 symbols')
                .required("Please enter your full name."),
              cName: Yup.string()
                .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s\-']*$/, "Invalid company name.")
                .max(1000, 'Max 1000 symbols')
                .required("Please enter your company name."),
              password: Yup.string()
                .matches(
                  /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-?'`])(?!.*\s).{8,}$/,
                  "Password is too weak. Include at least 1 number and 1 special character."
                )
                .required("Password should not be blank."),
              phone: Yup.string()
                .required("Please enter phone number.")
                .matches(/^[+\d\s()-]{7,15}$/, 'Invalid phone number format'),
            })}
            onSubmit={(values) => handleSaveDetails(values)}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit
            }) => (
              <Form 
                translate={undefined}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}
              >
                <TextField
                  name="name"
                  value={values['name']}
                  placeholder={'Full name'}
                  label={'Full Name'}
                  error={touched['name'] && errors['name']}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />
                <TextField
                  name="cName"
                  value={values['cName']}
                  placeholder={'Company Name'}
                  label={'Company Name'}
                  error={touched['cName'] && errors['cName']}
                  onChange={(e) => setFieldValue('cName', e.target.value)}
                />
                <TextField
                  name="password"
                  value={values['password']}
                  placeholder={'Enter Password'}
                  label={'Password'}
                  type={'password'}
                  error={touched['password'] && errors['password']}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                />
                <TextField
                  name="phone"
                  value={values['phone']}
                  placeholder={'Enter phone number'}
                  label={'Insert your phone connected to your WhatsApp'}
                  type={'number'}
                  error={touched['phone'] && errors['phone']}
                  onChange={(e) => setFieldValue('phone', e.target.value)}
                />
                <Box 
                  style={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    marginBottom: '16px'
                  }}
                >
                  <Field
                    as={Checkbox}
                    name="addToCommunity"
                    checked={values.addToCommunity}
                    checkedIcon={<img width={20} height={20} src={CheckboxCheckedIcon} />} //custom checknbox
                    icon={<img width={20} height={20} src={CheckboxIcon} />} //customcheckbox icon
                    onChange={() => setFieldValue('addToCommunity', !values.addToCommunity)}
                  />
                  <Typography
                    onClick={() => setFieldValue('addToCommunity', !values.addToCommunity)}
                    style={{ fontSize: '14px', fontWeight: '500', lineHeight: '22px', color: '#475569', cursor: 'pointer', marginTop: '6px' }}
                  >
                    I’d like to be added to the Pebbles Ai Beta community
                    <Typography
                      style={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', color: '#475569' }}
                    >
                      As one of our first 1,000 users, you'll help shape the future of our product, enjoy early access to new features, and be the first to know about future deals.
                    </Typography>
                  </Typography>
                </Box>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default AccountDetails;

import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import TextField from "../../../../components/main/CustomFields/TextField";
import { saveNewPassword } from "../../../../redux/services/authentication";
import { getUrlAndParams, removeUrlParams } from "../../../../helpers/other";
import { enqueueSnackbar } from "notistack";

import { useStyles } from "./styles";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const { params } = getUrlAndParams();
    const token = params.get('token');
    const email = params.get('email');

    if (!token || !email) {
      history.push('/');
    } else {
      setToken(token);
      setEmail(email);

      removeUrlParams();
    }
  }, []);

  const handleSaveNewPassword = async (newPassword: string, confirmPassword: string) => {
    setLoading(true);

    const params = {
      token,
      email
    }

    const body = {
      new_password: newPassword,
      password_confirmation: confirmPassword,
    }

    const { data, error } = await saveNewPassword({ params, body });

    if (data && !error) {
      history.push('/password-recovery-success');
    } else {
      enqueueSnackbar(
        Object.values(error.data.errors[0]).join() || 'Something went wrong',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.containerStyle}>
        <Box className={classes.signupform}>
          <Typography
            align="center"
            className={classes.typographyStyle1}
            variant="h6"
          >
            New Password
          </Typography>
          <Box>
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object({
                newPassword: Yup.string()
                  .required("New Password is required.")
                  .min(6, "Password must be at least 6 characters.")
                  .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                  ),
                confirmPassword: Yup.string()
                  .required("Confirm Password is required.")
                  .oneOf([Yup.ref("newPassword"), ""], "Both Passwords must match.")
              })}
              onSubmit={(values) => handleSaveNewPassword(values.newPassword, values.confirmPassword)}
            >
              {({
                errors,
                values,
                touched,
                setFieldValue
              }) => (
                <Form
                  translate={undefined}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    marginTop: '29px'
                  }}
                >
                  <TextField
                    name={'newPassword'}
                    value={values['newPassword']}
                    label={'New Password'}
                    placeholder={'Your new password'}
                    type={'password'}
                    error={touched['newPassword'] && errors['newPassword']}
                    onChange={(e) => setFieldValue('newPassword', e.target.value)}
                  />
                  <TextField
                    name={'confirmPassword'}
                    value={values['confirmPassword']}
                    label={'Confirm New Password'}
                    placeholder={'Re-enter your new password'}
                    type={'password'}
                    error={touched['confirmPassword'] && errors['confirmPassword']}
                    onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      marginTop: '16px'
                    }}
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;

import React, { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { ReactComponent as GoldPlusIcon } from '../../../assets/icons/gold_plus_icon.svg';
import CircularProgress from '../CustomFields/CircularProgress';
import TopUpModal from './components/TopUpModal';
import PaymentModal from './components/PaymentModal';
import { getTopUps, getWallet } from '../../../redux/services/wallet';
import { getCurrentSubscription } from '../../../redux/services/subscriptions';
import { observableService } from '../../../services/observable';

import { useStyles } from './styles';

interface Props {
  type: 'ONBOARDING' | 'COPILOT' | 'TACTICAL_OUTREACH'
}

const LimitationWidget: React.FC<Props> = (props) => {
  // const [observable, setObservable] = useState<any>(null);
  const [hover, setHover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showTopUpModal, setShowTopUpModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<{ active: boolean, option: any }>({
    active: false,
    option: null
  });
  const [limits, setLimits] = useState<{ total: number, current: number }>({
    total: 0,
    current: 0
  });
  const [type, setType] = useState<'ONBOARDING' | 'COPILOT' | 'TACTICAL_OUTREACH' | null>(null);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);

  const open = Boolean(anchorEl);
  const classes = useStyles({ open });

  useEffect(() => {
    handleGetWallet();
    handleGetCurrentSubscription();
  }, []);

  useEffect(() => {
    if (limits) {
      const observable = observableService.onEvent().subscribe((message) => {
        if (message === 'Refetch usage amount') {
          handleGetWallet();
        }

        if (message === 'Increase usage amount') {
          setLimits({
            ...limits,
            current: limits.current + 1
          })
        }

        if (message === 'Decrease usage amount') {
          setLimits({
            ...limits,
            current: limits.current <= 0 ? 0 : limits.current - 1
          })
        }

        if (message.includes('Show top up modal')) {
          const type = message.replace('Show top up modal for ', '');

          setShowTopUpModal(true);
          setType(type);
        }
      });

      // setObservable(observable);

      return () => {
        observable.unsubscribe(); // Unsubscribe on component unmount
      };
    }
  }, [limits]);

  const handleGetWallet = async () => {
    const { data, error } = await getWallet();

    if (data && !error) {
      setLimits({
        total: data.limits[props.type],
        current: data.actions[props.type]
      })
    }
  };

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      setCurrentSubscription(data);
    }
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    handleGetWallet();
  };

  return (
    <>
      <Box
        className={`${classes.widget} ${open ? classes.widgetOpen : ''}`}
        onClick={handleOpen}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CircularProgress
          value={limits.current}
          totalValue={limits.total}
          showInnerContent={false}
          sizes={{
            width: '20px',
            height: '20px'
          }}
          colors={{
            primary: open || hover ? 'white' : '#CAB354',
            secondary: open || hover ? '#FFFFFF26' : '#CAB35426',
            background: open || hover ? 'black' : 'white',
          }}
          strokeWidth='4px'
          allowOverlap
        />
        <Typography style={{ display: 'flex', alignItems: 'flex-end', gap: '4px', fontSize: '14px', fontWeight: '600', lineHeight: '21px' }}>
          {limits.current}
          <Typography style={{ fontSize: '14px', fontWeight: '400', lineHeight: '21px', color: '#475569' }}>
            / {limits.total}
          </Typography>
        </Typography>
      </Box>

      <Menu
        className={classes.widgetMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <Typography style={{ fontSize: '10px', fontWeight: '500', lineHeight: '12px', color: '#CBD5E1' }}>
          Till {moment(currentSubscription?.validTill).format('DD MMM YYYY')}
        </Typography>
        <CircularProgress
          value={limits.current}
          totalValue={limits.total}
          additionalText={props.type === 'ONBOARDING'
            ? <>Oboarding <br /> auto-fills</>
            : props.type === 'COPILOT'
              ? <>Copilot <br /> messages</>
              : <>generation <br /> emails</>
          }
          sizes={{
            width: '135px',
            height: '135px'
          }}
          colors={{
            primary: '#FFE4A9',
            secondary: '#FFF8DC33',
            background: '#000000',
            value: '#FFE4A9',
            totalValue: '#FFFFFF',
            additionalText: '#FFFFFF',
          }}
          strokeWidth='3px'
          allowOverlap
        />
        <Button
          fullWidth
          variant={'outlined'}
          className={classes.addButton}
          startIcon={<GoldPlusIcon />}
          onClick={() => {
            setAnchorEl(null)
            setShowTopUpModal(true)
          }}
        >
          Add
        </Button>
      </Menu>

      <TopUpModal
        open={showTopUpModal}
        type={props.type || type}
        limitReached={!!type}
        onClose={() => setShowTopUpModal(false)}
        showPaymentModal={(option) => {
          setShowPaymentModal({
            active: true,
            option
          })
        }}
      />

      <PaymentModal
        open={showPaymentModal.active}
        order={showPaymentModal.option}
        redirect={false}
        onClose={() => {
          setShowPaymentModal({
            active: false,
            option: null
          });

          observableService.sendEvent('Refetch usage amount');
        }}
      />
    </>
  );
};

export default LimitationWidget;

import React from 'react';
import { Box, Button, FormHelperText, IconButton, RadioGroup, Typography } from "@material-ui/core";
import { ReactComponent as InfoHintMiniIcon } from '../../../../../../assets/icons/info_hint_mini.svg';
import { ReactComponent as ClearIcon } from '../../../../../../assets/icons/close.svg';
import RadioButton from '../../../../../../components/main/CustomFields/RadioButton';
import SubSectionElement from '../..';
import { findElementByName, generateValidationRules, isFieldNotEmpty } from '../../../../helpers';
import { COUNTRIES_FLAG } from '../../../../../../utils/countries';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  validationSchema: any;
  template: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setValidationSchema: (shape: any) => void;
  setTooltipMessage: (message: string) => void;
}

const RenderRadioButtons: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  validationSchema,
  template,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setValidationSchema,
  setTooltipMessage,
}) => {
  const classes = useStyles();

  const handleSetValue = (name: string, value: any, element?: any) => {
    // Clear related fields and errors if the value has changed
    Object.keys(values).forEach(key => {
      if (key.startsWith(name) && name !== key) {
        setFieldValue(key, '');
      }
    });

    Object.keys(errors).forEach(key => {
      if (key.startsWith(name) && name !== key) {
        setFieldError(key, '');
      }
    });

    const updatedValidationSchema = { ...validationSchema };

    Object.keys(validationSchema).forEach(key => {
      if (key.startsWith(name) && name !== key) {
        delete updatedValidationSchema[key];
      }
    });

    if (value.length && element?.element?.id) {
      const nestedName = `${name}-${element.element.id}`;
      const rules = generateValidationRules(nestedName, element.element, item);

      updatedValidationSchema[nestedName] = rules;
    }

    setValidationSchema(updatedValidationSchema);
    setFieldValue(name, value);
  };

  const renderOptions = (item: any, name: string) => {
    const newOptionsAttribute = item?.attributes?.find(attr => attr.type === "RESET_OPTIONS_UNDER_CONDITION_ATTRIBUTE");
    let options = [];

    if (newOptionsAttribute && values[(Object.values(newOptionsAttribute.elementReference)).join('-')] === newOptionsAttribute.option) {
      options = newOptionsAttribute.newOptions;

      if (isFieldNotEmpty(values[name]) && !options.includes(values[name])) {
        setFieldValue(name, '');
        setFieldError(name, '');
      }
    } else {
      options = item.options;
    }

    return options.map((option: any, index: number) => {
      const element = item.attributes?.find(attr => attr.type === 'ON_OPTION_CHILD_ATTRIBUTE' && attr.option === option);
      const selected = values[name] === option;

      return (
        <Box
          key={index}
          style={selected && element?.element?.type === 'TEXT_AREA' ? {
            position: 'relative',
            padding: '8px',
            background: '#F3F3F3',
            borderRadius: '8px',
          } : {}}
        >
          {selected && element?.element?.type === 'TEXT_AREA' && <Box className={classes.nestedSeparator} />}
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <RadioButton
              name={name}
              label={
                <>
                  <img src={COUNTRIES_FLAG[option]} alt="" />&nbsp;
                  {option}&nbsp;
                  {item.attributes?.some(attr => attr.type === 'ON_OPTION_TOOLTIP_ATTRIBUTE') && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        const tooltip = item.attributes?.find(attr => attr.type === 'ON_OPTION_TOOLTIP_ATTRIBUTE')?.optionTooltipMap[option];
                        setTooltipMessage(tooltip);
                      }}
                    >
                      <InfoHintMiniIcon />
                    </IconButton>
                  )}
                </>
              }
              checked={selected}
              onChange={(event) => handleSetValue(event.target.name, option, element)}
            />
            {selected && (
              <Button
                variant="text"
                startIcon={<ClearIcon />}
                onClick={() => handleSetValue(name, '')}
                style={{
                  height: '1px',
                  minHeight: '1px',
                  maxHeight: '1px',
                  padding: '0px 8px',
                }}
              >
                Clear
              </Button>
            )}
          </Box>
          {selected && element && (
            <>
              <Box
                className={`
                  ${classes.grouppedRadioButtonWrapper}
                  ${(element.element.groups?.length || element.element.options?.length || element.element) ? classes.paddings : ''}
                  ${element.element.groups?.length ? classes.gaps : ''}
                `}
                style={{
                  boxSizing: !element.element.groups?.length && !element.element.options?.length ? 'border-box' : 'content-box',
                }}
              >
                {element.element.groups?.length
                  ? element.element.groups.map((subElem, index) => (
                      <Box key={index} className={classes.grouppedRadioButton}>
                        {subElem.label && (
                          <Typography style={{ fontSize: '12px', fontWeight: '600', lineHeight: '14px', color: '#64748B' }}>
                            {subElem.label}
                          </Typography>
                        )}
                        <RadioGroup>
                          {renderOptions(subElem, `${name}-${element.element.id}`)}
                        </RadioGroup>
                      </Box>
                    ))
                  : element.element.options?.length
                    ? <>
                        {renderOptions(element.element, `${name}-${element.element.id}`)}
                        {renderErrorMessage(`${name}-${element.element.id}`, false)}
                      </>
                    : (
                      <SubSectionElement
                        item={element.element}
                        name={`${name}-${element.element.id}`}
                        values={values}
                        errors={errors}
                        touched={touched}
                        validationSchema={validationSchema}
                        template={template}
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                        setValidationSchema={setValidationSchema}
                        setTooltipMessage={setTooltipMessage}
                      />
                    )}
              </Box>
              {!!element.element.groups?.length && (
                <Box className={classes.paddings} style={{ paddingTop: '0px' }}>
                  {renderErrorMessage(`${name}-${element.element.id}`, false)}
                </Box>
              )}
            </>
          )}
        </Box>
      );
    });
  };

  const renderErrorMessage = (name: string, touchedRequired = true) => (
    (touchedRequired ? touched[name] : true) && errors[name] && (
      <FormHelperText error>
        {errors[name]}
      </FormHelperText>
    )
  );

  return (
    <>
      <RadioGroup style={{ flexWrap: 'nowrap' }}>
        {renderOptions(item, name)}
      </RadioGroup>
      {renderErrorMessage(name, true)}
    </>
  );
};

export default RenderRadioButtons;

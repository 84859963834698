import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import TextField from "../../../components/main/CustomFields/TextField";
import { signUpUser } from "../../../redux/services/authentication";
import StorageProvider from "../../../framework/main/StorageProvider";
import { enqueueSnackbar } from "notistack";
import { clearStorages } from "../../../helpers/other";

import { useStyles } from "./styles";

const SignUp = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();

  const handleLogin = () => {
    history.push('/login');
  }

  const handleSignUp = async (email: string) => {
    setLoading(true);

    const { data, error } = await signUpUser({
      data: {
        type: "email_account",
        attribute: {
          email,
          "account_type": "professional"
        },
      }
    });

    if (data && !error) {
      await StorageProvider.setItem("user-token", data.meta.token);
      await StorageProvider.setItem("user-refresh-token", data.meta.refresh_token);

      history.push('/verify-signup-code');
    } else {
      await clearStorages();
      
      enqueueSnackbar(
        Object.values(error.data.errors[0]).join() || 'Failed to create account',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.layoutContainer}>
        <Box className={classes.emailContainer}>
          <Typography className={classes.sigupText}>
            Sign up
          </Typography>
          <Typography className={classes.subTitle}>
            Create an account to access Pebbles Ai.
          </Typography>
          <Typography className={classes.subTitle}>
            Already have an account?
            <span
              data-test-id="loginBtn"
              onClick={() => handleLogin()}
              className={classes.loginText}
            >
              {` Log in`}
            </span>
          </Typography>
          <Formik
            data-testid="signupEmailFormik"
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email address is not valid.")
                .required("Email should not be blank.")
            })}
            onSubmit={(values) => handleSignUp(values.email)}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <Form 
                translate={undefined}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '32px',
                  marginTop: '32px'
                }}
              >
                <TextField 
                  name={'email'}
                  value={values['email']}
                  label={'Email'}
                  placeholder={'Provide an email address'}
                  error={touched['email'] && errors['email']}
                  onChange={(e) => setFieldValue('email', e.target.value)}         
                />
                
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Continue with email
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;

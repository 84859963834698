import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: '0px',
    minWidth: '0px',
    maxWidth: '0px',
    height: '100vh',
    background: '#CBD5E1',
    borderLeft: '1px solid #969696',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    zIndex: 10,
    padding: '20px 0px',
    overflow: 'hidden',
    transition: 'all .3s'
  },
  sideBarOpen: {
    width: '270px',
    minWidth: '270px',
    maxWidth: '270px',
    padding: '20px 12px',
  },
  sideBarCloseButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px'
  },
  sideBarContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '5px 12px 5px 2px'
  },
  sideBarItem: {
    width: '218px',
    minWidth: '218px',
    maxWidth: '218px',
    // height: '93px',
    // minHeight: '93px',
    // maxHeight: '93px',
    background: '#FFFFFFB2',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    borderRadius: '8px',
    padding: '12px 16px',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  sideBarItemActive: {
    background: '#FFFFFF',
    // borderLeft: '2px solid #57BE99'
    boxShadow: '-2px 0 0 0 #57BE99 !important'
  },
}));

import React from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, ListItemText, MenuItem, Radio, Select, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDropDownIcon } from '../../../../assets/icons/dropdown_down.svg';
import CheckBoxIcon from '../../../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg';
import { COUNTRIES_FLAG } from '../../../../utils/countries';

import { useStyles } from './styles';

interface Props {
  name?: string;
  value: any;
  placeholder?: string;
  options: { value: string | number, label: string | number }[];
  optionsType?: 'default' | 'checkboxes';
  label?: string;
  required?: boolean;
  error?: string;
  onChange: (event: any, value: any) => void;
}

const Dropdown: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      error={!!props.error}
      variant="standard"
      className={classes.wrapper}
    >
      {props.label && (
        typeof props.label === 'string'
          ? <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#000000', marginBottom: '4px' }}>
            {props.label} &nbsp;
            {props.required && (
              <Typography style={{ color: 'red' }}>
                *
              </Typography>
            )}
          </Typography>
          : props.label
      )}
      <FormControlLabel
        control={
          <Select
            displayEmpty
            multiple={props.optionsType === 'checkboxes'}
            renderValue={(selected: any) => (
              selected?.length
                ? <Typography noWrap style={{ textAlign: 'left', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' }}>
                    {props.optionsType === 'checkboxes'
                      ? selected.join(', ')
                      : selected
                    }
                  </Typography>
                : props.placeholder
                  ? <Typography noWrap style={{ textAlign: 'left', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#94A3B8' }}>
                      {props.placeholder}
                    </Typography>
                  : null
            )}
            classes={{
              root: classes.select,
            }}
            IconComponent={ArrowDropDownIcon}
            MenuProps={{
              className: classes.menu,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {props.optionsType !== 'checkboxes' && props.placeholder && (
              <MenuItem
                value=""
                className={classes.item}
                style={{
                  color: '#475569'
                }}
              >
                {props.placeholder}
              </MenuItem>
            )}
            {props.options?.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                className={classes.item}
                onClick={(event) => {
                  if (props.optionsType === 'checkboxes') {
                    props.onChange(
                      event,
                      props.value.includes(item.value)
                        ? props.value.filter(elem => elem !== item.value)
                        : [
                            ...props.value,
                            item.value
                          ]
                    );
                  }
                }}
              >
                <img
                  src={COUNTRIES_FLAG[item.label || item.value]}
                  alt=''
                />
                {(!props.optionsType || props.optionsType === 'default') && (
                  item.label
                )}
                {props.optionsType === 'checkboxes' && (
                  <>
                    <Checkbox
                      icon={<img src={CheckBoxIcon} />}
                      checkedIcon={<img src={CheckBoxCheckedIcon} />}
                      checked={props.value.includes(item.value)}
                    />
                    <ListItemText
                      primary={item.label}
                      style={{
                        margin: '0',
                        textTransform: 'lowercase'
                      }}
                    />
                  </>
                )}
              </MenuItem>
            ))}
          </Select>
        }
        label={''}
        className={classes.controll}
        name={props.name}
        value={props.value || ''}
        onChange={props.optionsType === 'checkboxes' ? () => { } : props.onChange}
      />
      {props.error && (
        <FormHelperText error>
          {props.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Dropdown;
